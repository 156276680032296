/* stylelint-disable declaration-empty-line-before */
.container {
  padding: var(--unit_3) var(--unit_4);
  display: flex;
  gap: var(--unit_2);
  overflow: auto;
  width: 100%;
  background-color: white;
  flex-wrap: nowrap;

  -webkit-overflow-scrolling: touch;

  & > * {
    flex: 0 0 auto;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
