/* stylelint-disable */
.container {
  & :global(button) {
    background-color: #f3f6ff !important;
    padding: 4px 12px !important;
  }
  & :global(button:focus) {
    box-shadow: none !important;
  }
}
/* stylelint-enable */

.button {
  display: flex;
  font-weight: normal;
  color: var(--decorative-theme-dark);
  align-items: center;
  gap: 8px;
}
