.icon {
  padding: 4px;
  width: 24px;
  height: 24px;
  background-color: var(--accent-main-primary);
  border-radius: 4px;
}

.icon svg {
  display: block;
}
