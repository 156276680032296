.container {
  padding: var(--unit_4);
}

.apply-button {
  margin: var(--unit_1) 0;
}

.reset-button {
  margin: var(--unit_5) 0 var(--unit_1);
  padding: 0;
  display: flex;
  align-items: center;
  gap: var(--unit_2);
  cursor: pointer;
  background: none;
  border: 0;
  align-self: flex-start;
}
