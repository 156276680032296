.container + .container {
  margin-top: 40px;
}

.container + .container.container--compact {
  margin-top: 16px;
}

.title {
  padding: 0 16px;
  display: flex;
  align-items: center;
}

.info {
  margin-left: 8px;
}

.content.content--padding {
  padding: 0 16px;
}

.content.content--labeled {
  margin-top: 16px;
}
