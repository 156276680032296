.container {
  position: sticky;
  bottom: 0;
  padding: var(--unit_4);
  display: flex;
  width: 100%;
  background-color: white;
  border-top: 1px solid var(--gray10_100);
}

.action {
  width: 100%;
}
