.container + .container .content {
  border-top: 1px solid var(--gray10_100);
}

.container a {
  padding: 0 16px;
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-decoration: none;
  color: var(--decorative-theme-dark);
}

.wrapper {
  display: flex;
}

.image {
  margin-right: 12px;
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
}

.content {
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-width: 0;
}

.label {
  align-self: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.accessory {
  margin-left: 8px;
  align-self: center;
}

.accessory svg {
  display: block;
}
