.container {
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  height: 100%;
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.container-fixed {
  overflow-y: hidden;
}

.container-fixed .content {
  min-height: 0;
}
